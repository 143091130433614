import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Cookies } from 'react-cookie';
import { Row, Col, Card, Container } from 'reactstrap';

import { useAuth } from '../context/AuthContext';
import { AxiosError } from 'axios';
// services
import { authMagicCode } from '../services';
import { printEDCLog } from '../helpers';

const myProjectName = process.env.REACT_APP_PROJECT_NAME
  ? process.env.REACT_APP_PROJECT_NAME.toLowerCase()
  : 'rb1';

const cookies = new Cookies();

/**
 * HubMagicLoginPage component.
 *
 * @remarks
 * HubMagicLoginPage that parses and runs the command
 *
 * @component HubMagicLoginPage
 * @category Page
 */
const HubMagicLoginPage = (): JSX.Element => {
  const { clearState, setUser, setUserType, setIsAuthenticated } = useAuth();
  const { code } = useParams() as { code: string };
  const [error, setError] = useState('');

  const mounted = useRef(false);
  useEffect(() => {
    mounted.current = true;
    return () => {
      mounted.current = false;
    };
  });

  useEffect(() => {
    login();
  }, []);

  const login = async () => {
    clearState();
    try {
      const res = await authMagicCode(code, myProjectName);
      printEDCLog('login success : loginHubUser', res);

      if (res && res.data) {
        const userObj = {
          username: res.data.username!,
          firstName: res.data.firstName,
          lastName: res.data.lastName,
          email: res.data.username!,
          userType: 'coordinator',
          token: res.data.authToken!,
          role: res.data.role?.name,
        };
        cookies.set('authUser', userObj, { path: '/' });
        cookies.set('token', res.data.authToken, { path: '/' });
        cookies.set('type', 'coordinator', { path: '/' });
        setUser(userObj);
        setIsAuthenticated(true);
        setUserType('coordinator');
      }
    } catch (error) {
      const err = error as AxiosError;
      if (err.response && err.response.data) {
        setError(err.response.data.message ?? 'Username or password is not correct');
      }
    }
    if (!mounted.current) return;
  };

  return (
    <React.Fragment>
      <div className="container-fluid">
        <Container>
          <Row className="justify-content-center">
            <Col md={12}>
              <h4 className="flex-box-reverse"></h4>
              <Card className="overflow-hidden">
                <div className="p-2 text-center">{error && <div>{error}</div>}</div>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default HubMagicLoginPage;
