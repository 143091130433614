const myServerEnv = process.env.REACT_APP_SERVER_ENV ? process.env.REACT_APP_SERVER_ENV : 'uat';

export const printEDCLog = (...params: any[]): void => {
  if (myServerEnv.toLowerCase() === 'uat') {
    console.log(...params);
  }
};

export const isJson = (str: string): boolean => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};
