import React, { useState, useEffect } from 'react';
import { Cookies } from 'react-cookie';

import { DataDictionary, EDCPortalConfig, DataRecord } from '@lainaedge/platformshared';
import { DataDictionaryTableType } from '@lainaedge/platformshared/src/types/DataDictionaryType';
import { MenuItem, BreadCrumb } from '../constants/types';
import { PingResult } from '@lainaedge/platformshared/src/types/PingResult';

const cookies = new Cookies();

export interface IDataContext {
  config?: EDCPortalConfig;
  setConfig: (val: EDCPortalConfig) => void;
  mobile_config: any;
  dataDictionaryMaster?: DataDictionary;
  participantOverviewFields: DataDictionaryTableType[];
  records: DataRecord[];

  footer: string;

  versionInfo?: PingResult;
  setVersionInfo: (val: PingResult) => void;
  dashTitle: string;
  setDashTitle: (val: string) => void;
  title_text: string;
  setTitleText: (val: string) => void;
  footer_text: string;
  setFooterText: (val: string) => void;
  footer_text_right: string;
  setFooterRightText: (val: string) => void;
  menuItems: Array<MenuItem>;
  setMenuItems: (val: Array<MenuItem>) => void;
  menuUpdated: boolean;
  setMenuUpdate: (val: boolean) => void;
  breadCrumbs: BreadCrumb[];
  setBreadCrumbs: (val: BreadCrumb[]) => void;
  logo: string;
  setLogo: (val: string) => void;
  logoSm: string;
  setLogoSm: (val: string) => void;
  showQuickMenu: boolean;
  setShowQuickMenu: (val: boolean) => void;
  showGearModal: boolean;
  setShowGearModal: (val: boolean) => void;
  enableTrace: boolean;
  setEnableTrace: (val: boolean) => void;

  lang: string;
  leftSideBarType: string;
  setLeftSideBarType: (val: string) => void;
  isMobile: boolean;
  changeLanguage: (val: string) => void;
  logicValues: Array<any>;
  setLogicValues: (val: Array<any>) => void;
  pageTitle: string; // Page Title for Participant
  setPageTitle: (val: string) => void;
  bookmarked: boolean;
  setBookmark: (val: boolean) => void;
  showBookmarkModal: boolean;
  setShowBookmarkModal: (val: boolean) => void;
}

const defaultState = {
  mobile_config: {},
  dataDictionaryMaster: undefined,
  participantOverviewFields: [],
  records: [] as Array<DataRecord>,
  parentTitle: '',
  childTitle: '',
  listLink: '',
  footer: '',

  versionInfo: undefined,
  setVersionInfo: () => {},
  config: undefined,
  setConfig: () => {},
  dashTitle: '',
  setDashTitle: () => {},
  title_text: 'LAINA EDC PORTAL',
  setTitleText: () => {},
  footer_text: new Date().getFullYear() + ' © Laina',
  setFooterText: () => {},
  footer_text_right: 'Current Server time: ' + new Date().getFullYear(),
  setFooterRightText: () => {},
  menuItems: [] as Array<MenuItem>,
  setMenuItems: () => {},
  menuUpdated: false,
  setMenuUpdate: () => {},
  logo: 'laina-logo.png',
  setLogo: () => {},
  logoSm: 'laina-logo-sm.png',
  setLogoSm: () => {},
  breadCrumbs: [],
  setBreadCrumbs: () => {},
  showQuickMenu: false,
  setShowQuickMenu: () => {},
  showGearModal: false,
  setShowGearModal: () => {},
  enableTrace: false,
  setEnableTrace: () => {},

  lang: 'en',
  leftSideBarType: 'default',
  setLeftSideBarType: () => {},
  isMobile: false,
  changeLanguage: () => {},
  logicValues: [],
  setLogicValues: () => {},
  pageTitle: '',
  setPageTitle: () => {},
  bookmarked: false,
  setBookmark: () => {},
  showBookmarkModal: false,
  setShowBookmarkModal: () => {},
};

export const DataContext: React.Context<IDataContext> =
  React.createContext<IDataContext>(defaultState);

function changeSidebarType(sidebarType: string) {
  switch (sidebarType) {
    case 'compact':
      changeBodyAttribute('data-sidebar-size', 'small');
      manageBodyClass('sidebar-enable', 'remove');
      manageBodyClass('vertical-collpsed', 'remove');
      break;
    case 'icon':
      changeBodyAttribute('data-keep-enlarged', 'true');
      manageBodyClass('vertical-collpsed', 'add');
      break;
    case 'condensed':
      manageBodyClass('sidebar-enable', 'add');
      manageBodyClass('vertical-collpsed', 'add');
      break;
    case 'menu-opened':
      manageBodyClass('sidebar-enable', 'add');
      manageBodyClass('menu-opened', 'add');
      manageBodyClass('vertical-collpsed', 'add');
      break;
    case 'menu-closed':
      manageBodyClass('sidebar-enable', 'add');
      manageBodyClass('menu-opened', 'remove');
      manageBodyClass('vertical-collpsed', 'add');
      break;
    default:
      changeBodyAttribute('data-sidebar-size', '');
      manageBodyClass('sidebar-enable', 'remove');
      manageBodyClass('vertical-collpsed', 'remove');
      manageBodyClass('menu-opened', 'remove');
      break;
  }
}

function changeBodyAttribute(attribute: string, value: string) {
  if (document.body) document.body.setAttribute(attribute, value);
  return true;
}

/**
 * Toggle the class on body
 * @param {*} cssClass
 */
function manageBodyClass(cssClass: string, action = 'toggle') {
  switch (action) {
    case 'add':
      if (document.body) document.body.classList.add(cssClass);
      break;
    case 'remove':
      if (document.body) document.body.classList.remove(cssClass);
      break;
    default:
      if (document.body) document.body.classList.toggle(cssClass);
      break;
  }

  return true;
}

export const DataProvider = ({ children }: { children: any }): JSX.Element => {
  const [versionInfo, setVersionInfo] = useState<PingResult>();
  const [config, setConfig] = useState<EDCPortalConfig>();
  const [dashTitle, setDashTitle] = useState<string>('');
  const [title_text, setTitleText] = useState<string>('');
  const [footer_text, setFooterText] = useState<string>(new Date().getFullYear() + ' © Laina');
  const [footer_text_right, setFooterRightText] = useState<string>('');
  const [menuItems, setMenuItems] = useState<Array<MenuItem>>([]);
  const [menuUpdated, setMenuUpdate] = useState<boolean>(false);
  const [breadCrumbs, setBreadCrumbs] = useState<Array<BreadCrumb>>([]);
  const [logo, setLogo] = useState<string>('laina-logo.png');
  const [logoSm, setLogoSm] = useState<string>('laina-logo-sm.png');
  const [leftSideBarType, setLeftSideBarType] = useState<string>('default');
  const [showQuickMenu, setShowQuickMenu] = useState<boolean>(false);
  const [showGearModal, setShowGearModal] = useState<boolean>(false);
  const [enableTrace, setEnableTrace] = useState<boolean>(cookies.get('enableTrace') || false);
  const [logicValues, setLogicValues] = useState<Array<any>>([]);
  const [pageTitle, setPageTitle] = useState<string>('');
  const [bookmarked, setBookmark] = useState<boolean>(false);
  const [showBookmarkModal, setShowBookmarkModal] = useState<boolean>(false);

  function changeLanguage(lang: string) {
    console.log(lang);
  }

  useEffect(() => {
    changeSidebarType(leftSideBarType);
  }, [leftSideBarType]);

  const value = {
    mobile_config: {},
    dataDictionaryMaster: undefined,
    participantOverviewFields: [],
    records: [],
    parentTitle: '',
    childTitle: '',
    listLink: '',
    footer: '',

    versionInfo,
    setVersionInfo,
    config,
    setConfig,
    dashTitle,
    setDashTitle,
    title_text,
    setTitleText,
    footer_text,
    setFooterText,
    footer_text_right,
    setFooterRightText,
    menuItems,
    setMenuItems,
    menuUpdated,
    setMenuUpdate,
    breadCrumbs,
    setBreadCrumbs,
    logo,
    setLogo,
    logoSm,
    setLogoSm,
    showQuickMenu,
    setShowQuickMenu,
    showGearModal,
    setShowGearModal,
    enableTrace,
    setEnableTrace,

    lang: 'en',
    leftSideBarType,
    setLeftSideBarType,
    changeLanguage,
    isMobile: false,
    logicValues,
    setLogicValues,
    pageTitle,
    setPageTitle,
    bookmarked,
    setBookmark,
    showBookmarkModal,
    setShowBookmarkModal,
  };

  return <DataContext.Provider value={value}>{children}</DataContext.Provider>;
};

export const useData = (): IDataContext => React.useContext(DataContext);
